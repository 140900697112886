import { CSS, styled } from '@air/marketing-primitives';
import { ComponentProps } from 'react';

export type RootProps = ComponentProps<typeof Root> & {
  css?: CSS;
};

export const Root = styled('div', {
  display: 'flex',
});
