export const FOOTER = 'FOOTER';
export const HEADER = 'HEADER';
export const HEADER_MENU = 'HEADER_MENU';
export const HEADER_MENU_BACKGROUND = 'HEADER_MENU_BACKGROUND';
export const HEADER_MENU_ITEM = 'HEADER_MENU_ITEM';
export const HEADER_MENU_ITEM_ADORNMENT = 'HEADER_MENU_ITEM_ADORNMENT';
export const HEADER_MOBILE_HAMBURGER = 'HEADER_MOBILE_HAMBURGER';
export const HEADER_NAVIGATION = 'HEADER_NAVIGATION';
export const HEADER_NAVIGATION_ITEM = 'HEADER_NAVIGATION_ITEM';
export const HEADER_NAVIGATION_BUTTON = 'HEADER_NAVIGATION_BUTTON';
export const LINK = 'LINK';
export const LOGO = 'LOGO';
