import {
  Apple,
  Cart,
  Check,
  Dart,
  Device,
  Email,
  Emoji,
  Eye,
  Home,
  Info,
  Instagram,
  Kanban,
  Link as LinkIcon,
  LinkedIn,
  Lock,
  MagicWand,
  MagnifyingGlass,
  Megaphone,
  Move,
  NavBlog,
  NavCulture,
  NavDiversity,
  NavPolicies,
  Palette,
  Popcorn,
  Send,
  Soccer,
  Stack,
  Team,
  Tiktok,
  Twitter,
  YouTube,
} from '@air/icons';
import { CSS, Text } from '@air/marketing-primitives';
import Image from 'next/image';
import Link from 'next/link';
import { ReactNode } from 'react';

import { HeaderMenuBanner } from '~/old-marketing/components/Header/HeaderMenuBanner';
import { HeaderMenuItemAdornment } from '~/old-marketing/components/Header/HeaderMenuItemAdornment';
import { BlogRoutes, Routes, SharedRoutes, SocialMediaRoutes } from '~/old-marketing/constants/Routes';

export type HeaderNavigationMenuColumn = {
  name: string;
  hasDivider?: boolean;
  chunkSize?: number;
  css?: CSS;
};

export type HeaderNavigationMenuColumnItem = {
  adornment?: ReactNode;
  description?: string;
  name: string;
  href: string;
};

export type HeaderNavigationItem = {
  name: string;
  href?: string;
} & {
  color?: string;
  template?: string;
  columns?: (HeaderNavigationMenuColumn &
    (
      | {
          items: HeaderNavigationMenuColumnItem[];
        }
      | { children: ReactNode }
    ))[];
};

export const HEADER_NAVIGATION: HeaderNavigationItem[] = [
  {
    name: 'Product',
    color: '#F0F3FF',
    columns: [
      {
        name: 'Features',
        chunkSize: 4,
        items: [
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <Dart className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Smart search',
            description: 'We auto-tag your content',
            href: Routes.featuresSmartSearch,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <Palette className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Dynamic feedback',
            description: 'Timestamp and point-based comments',
            href: Routes.featuresDynamicFeedback,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <Kanban className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Kanban',
            description: 'Organize assets for your workflow',
            href: Routes.featuresKanban,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <Stack className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Versions',
            description: 'Every asset is a folder for its own drafts',
            href: Routes.featuresVersions,
          },
        ],
      },
      {
        name: '',
        chunkSize: 4,
        css: {
          marginTop: 20,
        },
        items: [
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <Move className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Flexible organization',
            description: 'Customize your workspace',
            href: Routes.featuresFlexibleOrganization,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <Eye className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Visual workspace',
            description: 'See all of your assets clearly',
            href: Routes.featuresVisualWorkspace,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <Device className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Mobile and desktop apps',
            description: 'Simple, fast uploads from your devices to Air',
            href: Routes.download,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <LinkIcon className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Integrations',
            description: 'Seamlessly integrate Air with your favorite tools',
            href: Routes.integrations,
          },
        ],
      },
      {
        name: 'see air in action',
        children: (
          <HeaderMenuBanner
            description="We’ll show you how Air can put time back in your day"
            image={<Image alt="" height={161} src="/assets/marketing/header-menu-product.svg" width={160} />}
            isDemo
            cta={
              <Text
                as="a"
                css={{ color: '$jay350', fontWeight: '$medium', textDecoration: 'none', cursor: 'pointer' }}
                size="ui-14"
              >
                Book a demo →
              </Text>
            }
            title="Talk to a human"
          />
        ),
      },
    ],
    template: 'product',
  },
  {
    name: 'Customers',
    color: '$canary050',
    columns: [
      {
        name: 'By industry',
        chunkSize: 3,
        hasDivider: true,
        items: [
          {
            adornment: (
              <HeaderMenuItemAdornment css={{ backgroundColor: '$cuckoo100', color: '$cuckoo600' }}>
                <Cart className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'E-commerce & DTC',
            description: 'Centralize retail & marketing work',
            href: Routes.industryRetail,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment css={{ backgroundColor: '$peacock300', color: '$peacock700' }}>
                <Megaphone className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Agencies',
            description: 'Create a workspace for each client relationship',
            href: Routes.industryAgencies,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment css={{ backgroundColor: '$canary300', color: '$canary700' }}>
                <Popcorn className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Media & Entertainment',
            description: 'Build a central library for all your visuals',
            href: Routes.industryMedia,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment css={{ backgroundColor: '$parrot050', color: '$parrot600' }}>
                <Home className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Non-profits',
            description: 'Spend less time in the weeds, more time doing good work',
            href: Routes.industryNonprofits,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment css={{ backgroundColor: '$macaw040', color: '$macaw350' }}>
                <Soccer className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Athletics & Sports',
            description: 'Every photo and video, stored and easily shareable',
            href: Routes.industryAthletics,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment css={{ backgroundColor: '$flamingo200', color: '$flamingo800' }}>
                <Apple className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Education ',
            href: Routes.industryEducation,
            description: 'Store and distribute visuals easily',
          },
        ],
      },
      {
        name: 'By team',
        chunkSize: 4,
        items: [
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <Dart className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Marketing',
            description: 'Accelerate campaign management',
            href: Routes.departmentMarketing,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <Palette className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Creative',
            description: 'Spend more time creating & less time organizing',
            href: Routes.departmentDesignCreative,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <Kanban className="block w-6 rotate-180" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Enterprise',
            description: 'Security, services and pricing for scale',
            href: Routes.enterprise,
          },
        ],
      },
      {
        name: 'Customer stories',
        children: (
          <HeaderMenuBanner
            cta={
              <Link href={Routes.customerStories} passHref>
                <Text as="a" css={{ color: '$jay350', fontWeight: '$medium', textDecoration: 'none' }} size="ui-14">
                  Learn more →
                </Text>
              </Link>
            }
            description="Read how Air saves teams time and headaches"
            image={<Image alt="product image" height={161} src="/blog/assets/header-menu-enterprise.svg" width={160} />}
            title="Top brands love Air"
          />
        ),
      },
    ],
    template: 'enterprise',
  },
  {
    name: 'Learn',
    color: '$peacock100',
    columns: [
      {
        name: 'Switch from',
        chunkSize: 4,
        items: [
          {
            name: 'Dropbox',
            href: Routes.comparisonDropboxAlternative,
          },
          {
            name: 'Google Drive',
            href: Routes.comparisonGoogleDriveAlternative,
          },
          {
            name: 'Box',
            href: Routes.comparisonBox,
          },
          {
            name: 'DAM',
            href: Routes.comparisonDAM,
          },
        ],
      },
      {
        name: 'Resources',
        chunkSize: 4,
        hasDivider: true,
        items: [
          {
            name: 'Help center',
            href: SharedRoutes.helpCenter,
          },
          {
            name: 'Status page',
            href: SharedRoutes.status,
          },
          {
            name: 'Security',
            href: Routes.security,
          },
          {
            name: 'Release notes',
            href: Routes.releaseNotes,
          },
        ],
      },
      {
        name: 'Blog',
        chunkSize: 5,
        items: [
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <NavBlog className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Blog homepage',
            href: Routes.blog,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <Palette className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Creative ops',
            href: BlogRoutes.creativeOps,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <MagicWand className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Building brands',
            href: BlogRoutes.buildingBrands,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <Email className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Air news',
            href: BlogRoutes.airNews,
          },
        ],
      },
      {
        name: 'Air does it better',
        children: (
          <HeaderMenuBanner
            cta={
              <Link href="/landing/sem-lp-gated-buyout-4" passHref>
                <Text as="a" css={{ color: '$jay350', fontWeight: '$medium', textDecoration: 'none' }} size="ui-14">
                  Learn more →
                </Text>
              </Link>
            }
            description="Switch to Air and we’ll buy out your contract"
            image={
              <div className="mb-20">
                <Image alt="" height={151} src="/assets/marketing/header-menu-enterprise.svg" width={150} />
              </div>
            }
            title="DAM not your jam?"
          />
        ),
      },
    ],
    template: 'resources',
  },
  {
    name: 'Why Air?',
    color: '$peacock100',
    columns: [
      {
        name: 'Use cases',
        chunkSize: 4,
        items: [
          {
            adornment: (
              <HeaderMenuItemAdornment css={{ backgroundColor: '$macaw060', color: '$macaw500' }} size="small">
                <Team className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'External collaboration',
            href: Routes.tourOnboardFreelancers,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment css={{ backgroundColor: '$peacock300', color: '$peacock700' }} size="small">
                <Check className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Creative approvals',
            href: Routes.tourCreativeApprovals,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment css={{ backgroundColor: '$flamingo200', color: '$canary800' }} size="small">
                <Lock className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Usage & access',
            href: Routes.tourRightsManagement,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment css={{ backgroundColor: '$canary300', color: '$flamingo900' }} size="small">
                <Send className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Project management',
            href: Routes.tourCreativeRequests,
          },
        ],
      },
      {
        name: 'Company',
        chunkSize: 5,
        hasDivider: true,
        items: [
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <Info className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'About us',
            href: Routes.about,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <MagnifyingGlass className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Careers',
            href: Routes.careers,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <Emoji className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Investors',
            href: Routes.operators,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <Cart className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Air merch',
            href: Routes.airShop,
          },
        ],
      },
      {
        name: 'Life at Air',
        items: [
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <NavCulture className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Culture',
            href: Routes.culture,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <NavDiversity className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Diversity',
            href: Routes.diversity,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <Team className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Inclusion',
            href: Routes.inclusion,
          },
          {
            adornment: (
              <HeaderMenuItemAdornment size="small">
                <NavPolicies className="block w-6 h-6" />
              </HeaderMenuItemAdornment>
            ),
            name: 'Policies',
            href: Routes.culturePolicies,
          },
        ],
      },
      {
        name: 'Join our team',
        children: (
          <HeaderMenuBanner
            description="We’re growing fast and always hiring"
            image={
              <div className="m-[100px]">
                <Image alt="air product" height={151} src="/blog/assets/header-menu-resources.svg" width={150} />
              </div>
            }
            cta={
              <Text
                as="a"
                css={{ color: '$jay350', fontWeight: '$medium', textDecoration: 'none' }}
                href={Routes.careers}
                size="ui-14"
              >
                Learn more →
              </Text>
            }
            title="Explore open roles"
          />
        ),
      },
    ],
    template: 'why-air',
  },
  {
    name: 'Pricing',
    href: Routes.pricing,
  },
];

export type FooterNavigation = {
  title: string;
  items: {
    id?: string;
    label: string;
    href: string;
    type: 'external' | 'internal';
  }[];
  css: CSS;
}[];

export const FOOTER_NAVIGATION: FooterNavigation = [
  {
    title: 'Product',
    items: [
      { label: 'Smart search', href: Routes.featuresSmartSearch, type: 'internal' },
      { label: 'Dynamic feedback', href: Routes.featuresDynamicFeedback, type: 'internal' },
      { label: 'Kanban', href: Routes.featuresKanban, type: 'internal' },
      { label: 'Versions', href: Routes.featuresVersions, type: 'internal' },
      { label: 'Flexible organization', href: Routes.flexibleOrganization, type: 'internal' },
      { label: 'Visual workspace', href: Routes.visualWorkspace, type: 'internal' },
      { label: 'Desktop app', href: Routes.airFlow, type: 'internal' },
      { label: 'Mobile apps', href: Routes.download, type: 'internal' },
      { label: 'Integrations ', href: Routes.integrations, type: 'internal' },
      { label: 'Pricing', href: Routes.pricing, type: 'internal' },
    ],
    css: {
      order: 1,
    },
  },
  {
    title: 'Customers',
    items: [
      { label: 'Customer stories', href: Routes.customerStories, type: 'internal' },
      { label: 'E-commerce & DTC ', href: Routes.industryRetail, type: 'internal' },
      { label: 'Agencies', href: Routes.industryAgencies, type: 'internal' },
      { label: 'Media & Entertainment', href: Routes.industryMedia, type: 'internal' },
      { label: 'Non-profits', href: Routes.industryNonprofits, type: 'internal' },
      { label: 'Athletics & Sports', href: Routes.industryAthletics, type: 'internal' },
      { label: 'Education', href: Routes.industryEducation, type: 'internal' },
      { label: 'Marketing', href: Routes.departmentMarketing, type: 'internal' },
      { label: 'Creative', href: Routes.departmentDesignCreative, type: 'internal' },
      { label: 'Enterprise', href: Routes.enterprise, type: 'internal' },
    ],
    css: {
      order: 2,
    },
  },
  {
    title: 'Solutions',
    items: [
      { label: 'Dropbox alternative', href: Routes.comparisonDropboxAlternative, type: 'internal' },
      { label: 'Google Drive alternative', href: Routes.comparisonGoogleDriveAlternative, type: 'internal' },
      { label: 'Box alternative ', href: Routes.comparisonBox, type: 'internal' },
      { label: 'DAM alternative', href: Routes.comparisonDAM, type: 'internal' },
      { label: 'External collaboration', href: Routes.tourOnboardFreelancers, type: 'internal' },
      { label: 'Usage & access', href: Routes.tourRightsManagement, type: 'internal' },
      { label: 'Project management', href: Routes.tourCreativeRequests, type: 'internal' },
    ],
    css: {
      order: 3,
    },
  },
  {
    title: 'Resources',
    items: [
      { label: 'Help center', href: SharedRoutes.helpCenter, type: 'internal' },
      { label: 'Blog', href: Routes.blog, type: 'internal' },
      { label: 'Live events', href: Routes.liveEvents, type: 'internal' },
      { label: 'Release notes', href: Routes.releaseNotes, type: 'external' },
      { label: 'Status', href: SharedRoutes.status, type: 'external' },
      { label: 'Security', href: Routes.security, type: 'internal' },
      { label: 'Beta program', href: Routes.betaProgram, type: 'internal' },
    ],
    css: {
      order: 4,
    },
  },
  {
    title: 'Company',
    items: [
      { label: 'About us', href: Routes.about, type: 'internal' },
      { label: 'Careers', href: Routes.careers, type: 'external' },
      { label: 'Partner program', href: Routes.partnerProgram, type: 'external' },
      { label: 'Investors', href: Routes.operators, type: 'external' },
      { label: 'Culture', href: Routes.culture, type: 'internal' },
      { label: 'Diversity', href: Routes.diversity, type: 'internal' },
      { label: 'Inclusion', href: Routes.inclusion, type: 'internal' },
      { label: 'Policies', href: Routes.culturePolicies, type: 'internal' },
      { label: 'Air merch', href: Routes.airShop, type: 'external' },
    ],
    css: {
      order: 5,
    },
  },
];

export const FOOTER_SOCIAL_NAVIGATION = [
  {
    href: SocialMediaRoutes.tiktok,
    icon: Tiktok,
    label: 'TikTok',
  },
  {
    href: SocialMediaRoutes.instagram,
    icon: Instagram,
    label: 'Instagram',
  },
  {
    href: SocialMediaRoutes.twitter,
    icon: Twitter,
    label: 'Twitter',
  },
  {
    href: SocialMediaRoutes.linkedIn,
    icon: LinkedIn,
    label: 'LinkedIn',
  },
  {
    href: SocialMediaRoutes.youtube,
    icon: YouTube,
    label: 'YouTube',
  },
];
