import { CSS, styled, VariantProps } from '@air/marketing-primitives';
import { ComponentProps } from 'react';

export type RootVariants = VariantProps<typeof Root>;
export type RootProps = ComponentProps<typeof Root> &
  RootVariants & {
    css?: CSS;
  };

export const Root = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 44,
  height: 44,
  borderRadius: 4,
  color: '$jay300',

  variants: {
    size: {
      large: {
        width: 44,
        height: 44,
      },
      small: {
        width: 32,
        height: 32,
      },
    },
  },

  defaultVariants: {
    size: 'large',
  },
});
