import React from 'react';

import * as StyledHeaderMenuItemAdornment from '~/old-marketing/components/Header/HeaderMenuItemAdornment.styles';
import { HEADER_MENU_ITEM_ADORNMENT } from '~/old-marketing/constants/testIds';

export type HeaderMenuItemAdornmentProps = Pick<StyledHeaderMenuItemAdornment.RootProps, 'children' | 'css' | 'size'>;

export const HeaderMenuItemAdornment = ({ children, css, size }: HeaderMenuItemAdornmentProps) => {
  return (
    <StyledHeaderMenuItemAdornment.Root css={css} data-testid={HEADER_MENU_ITEM_ADORNMENT} size={size}>
      {children}
    </StyledHeaderMenuItemAdornment.Root>
  );
};
